import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "./css/Home.css";

export default function ApplySheroes() {
  const [formData, setFormData] = useState({
    parentFullName: "",
    parentPhoneNumber: "",
    parentEmail: "",
    parentAddress: "",
    wardFullName: "",
    wardGender: "",
    wardDOB: "",
    wardClass: "",
    wardDisabilityStatus: false,
    disability: "",
    t_shirt: false,
    schoolName: "",
    schoolLocation: "",
  });

  const [loading, setLoading] = useState(false); // Loading state for preloader
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

   // Function to handle GET request when page loads
   useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://fresh-link.onrender.com");
        if (response.ok) {
          const data = await response.json();
          console.log("Data fetched successfully:", data);
        } else {
          console.error("Failed to fetch data:", response.status);
        }
      } catch (error) {
        console.error("Error during GET request:", error);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Show preloader when form submission starts
    try {
      const response = await fetch("https://fresh-link.onrender.com/api/form", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const result = await response.json();
      if (response.ok) {
        alert(result.message);
        setIsSubmitted(true);
      } else {
        alert(result.message);
      }
    } catch (error) {
      console.error("Error:", error);
      setError("Error submitting application");
    } finally {
      setLoading(false); // Hide preloader after submission completes
    }
  };

  return (
    <div className="container-fluid">
      {loading && (
        <section className="preloader">
          <div className="spinner">
            <span className="sk-inner-circle"></span>
          </div>
        </section>
      )}
      <Header />
      <div
        className="container-fluid row mb-5 section-paddikng-img"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "10vh",
        }}
      >
        <div className="container-fluid col-lg-5 site-header rounded mb-5 p-3">
          <form onSubmit={handleSubmit} className="was-validated">
            <div className="mb-3">
              <label htmlFor="wardFullName" className="form-label">
                Ward's Full Name:
              </label>
              <input
                type="text"
                className="form-control"
                id="wardFullName"
                name="wardFullName"
                placeholder="Enter ward's full name"
                value={formData.wardFullName}
                onChange={handleChange}
                required
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Ward's gender:</label>
              <div>
                <div className="form-check">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="genderMale"
                    name="wardGender"
                    value="Male"
                    checked={formData.wardGender === "Male"}
                    onChange={handleChange}
                  />
                  <label htmlFor="genderMale" className="form-check-label">
                    Male
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="genderFemale"
                    name="wardGender"
                    value="Female"
                    checked={formData.wardGender === "Female"}
                    onChange={handleChange}
                  />
                  <label htmlFor="genderFemale" className="form-check-label">
                    Female
                  </label>
                </div>
              </div>
            </div>

            <div className="mb-3">
              <label htmlFor="wardDOB" className="form-label">
                Ward's Date of Birth:
              </label>
              <input
                type="date"
                className="form-control"
                id="wardDOB"
                name="wardDOB"
                value={formData.wardDOB}
                onChange={handleChange}
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="wardClass" className="form-label">
                Ward's Class:
              </label>
              <input
                type="text"
                className="form-control"
                id="wardClass"
                name="wardClass"
                placeholder="Enter ward's class"
                value={formData.wardClass}
                onChange={handleChange}
                required
              />
            </div>

            <div className="mb-3">
              <label className="form-label">
                Does your ward have any diability:
              </label>
              <div>
                <div className="form-check">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="disabilityYes"
                    name="wardDisabilityStatus"
                    value="true"
                    checked={formData.wardDisabilityStatus === "true"}
                    onChange={handleChange}
                  />
                  <label htmlFor="disabilityYes" className="form-check-label">
                    Yes
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="disabilityNo"
                    name="wardDisabilityStatus"
                    value="false"
                    checked={formData.wardDisabilityStatus === "false"}
                    onChange={handleChange}
                  />
                  <label htmlFor="disabilityNo" className="form-check-label">
                    No
                  </label>
                </div>
              </div>
            </div>

            <div className="mb-3">
              <label htmlFor="schoolName" className="form-label">
                If Yes, explain further:
              </label>
              <textarea
                type="text"
                className="form-control"
                id="schoolName"
                name="disability"
                placeholder=""
                value={formData.disability}
                onChange={handleChange}
                // required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="schoolName" className="form-label">
                School Name:
              </label>
              <input
                type="text"
                className="form-control"
                id="schoolName"
                name="schoolName"
                placeholder="Enter school name"
                value={formData.schoolName}
                onChange={handleChange}
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="schoolLocation" className="form-label">
                School Location:
              </label>
              <input
                type="text"
                className="form-control"
                id="schoolLocation"
                name="schoolLocation"
                placeholder="Enter school location"
                value={formData.schoolLocation}
                onChange={handleChange}
                required
              />
            </div>

            <div className="mb-3">
              <label className="form-label">
                Would like to secure a T-Shirt for your ward?:
              </label>
              <div>
                <div className="form-check">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="TshirtYes"
                    name="t_shirt"
                    value="true"
                    checked={formData.t_shirt === "true"}
                    onChange={handleChange}
                  />
                  <label htmlFor="TshirtYes" className="form-check-label">
                    Yes
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="TshirtNo"
                    name="t_shirt"
                    value="false"
                    checked={formData.t_shirt === "false"}
                    onChange={handleChange}
                  />
                  <label htmlFor="TshirtNo" className="form-check-label">
                    No
                  </label>
                </div>
              </div>
            </div>

            <div className="mb-3">
              <label htmlFor="parentFullName" className="form-label">
                Parent/Guardian Full Name:
              </label>
              <input
                type="text"
                className="form-control"
                id="parentFullName"
                name="parentFullName"
                placeholder="Enter parent's full name"
                value={formData.parentFullName}
                onChange={handleChange}
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="parentPhoneNumber" className="form-label">
                Parent/Guardian Phone Number:
              </label>
              <input
                type="tel"
                className="form-control"
                id="parentPhoneNumber"
                name="parentPhoneNumber"
                placeholder="Enter parent's phone number"
                value={formData.parentPhoneNumber}
                onChange={handleChange}
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="parentEmail" className="form-label">
                Parent/Guardian Email:
              </label>
              <input
                type="email"
                className="form-control"
                id="parentEmail"
                name="parentEmail"
                placeholder="Enter parent's email"
                value={formData.parentEmail}
                onChange={handleChange}
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="parentAddress" className="form-label">
                Parent/Guardian Address:
              </label>
              <textarea
                className="form-control"
                id="parentAddress"
                name="parentAddress"
                placeholder="Enter parent's address"
                value={formData.parentAddress}
                onChange={handleChange}
                required
              />
            </div>

            <button type="submit" className="btn col-12 btn-primary">
              Submit
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}
